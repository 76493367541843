// @ts-nocheck
import { useRoute } from "@react-navigation/native";
import { OFFoodOptions } from "@src/components/OFOptions";
import { useGetOneProduct } from "@src/hooks/useRequest";
import React from "react";
import { StyleSheet, Text, View, ImageBackground, Image } from "react-native";
import Hero from "../details/components/hero";
import { img2url } from "@src/utils/utils";
import { ScrollView } from "moti";
import { priceFormatter } from "@src/shared/utils/formatter";

export default function MenuDetails() {
  let { params } = useRoute<any>();
  let product = useGetOneProduct(params.id).data?.data || [];

  // sort product[].options by choices_number
  let options = product?.options?.sort((a, b) => {
    // desc
    return b.choices_number - a.choices_number;
  });

  return (
    <ScrollView style={styles.container}>
      <Hero image={img2url(product?.image)} />

      <View style={styles.description}>
        <Text style={styles.title}>{product?.name}</Text>
        <Text style={styles.price}>{priceFormatter(product?.price)}</Text>
      </View>

      {options?.map((option, index) => {
        // const value = option_store.options?.[option.optionID] as any;
        if (option.elements?.length == 0) return;
        return (
          <OFFoodOptions
            optionID={option.optionID as string}
            key={index}
            title={option.title}
            required={option.required}
            choices_number={option.choices_number}
            elements={option.elements}
            value={[]}
            onPress={(selected, id) => updateOptions(selected, id)}
          />
        );
      })}
      <View style={{ height: 50 }} />
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: "white",
    // alignItems: "center",
    // justifyContent: "center",
    paddingHorizontal: 10,
    paddingBottom: 20,
    // marginBottom: 50,
  },
  image: {
    width: 200,
    height: 200,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 20,
    width: "65%",
    color: "#333",
    // textAlign: "left",
  },
  description: {
    paddingHorizontal: 10,
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fafafa",
    borderRadius: 10,
  },
  price: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 20,
    width: "35%",
    textAlign: "right",
    borderLeftWidth: 1,
    borderLeftColor: "#ccc",
  },
});
