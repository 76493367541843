import { useQuery } from "react-query";
import { getCats, getProductByCat, getOneProduct } from "../api/request";

export function useGetCategories(cats: any) {
  let { data, isLoading, error } = useQuery(["get_categories", cats], getCats);
  return { data, isLoading, error };
}

export function useGetProductByCat(cat_id: any) {
  let { data, isLoading, error } = useQuery(
    ["get_product_by_cat", cat_id],
    getProductByCat
  );
  return { data, isLoading, error };
}

export function useGetOneCategory(cat_id: any) {
  let { data, isLoading, error } = useQuery(
    ["get_one_category", cat_id],
    getProductByCat
  );
  return { data, isLoading, error };
}

export function useGetOneProduct(product_id: any) {
  let { data, isLoading, error } = useQuery(
    ["get_one_product", product_id],
    getOneProduct
  );
  return { data, isLoading, error };
}
