import { StyleSheet, Text, View } from "react-native";
import React from "react";
import Hero from "./components/hero";
import Product from "./components/product";
import { Link, useRoute, useLinkTo } from "@react-navigation/native";
import SkeletonMenu from "../home/components/skeletonMenu";
import { useGetProductByCat } from "../../hooks/useRequest";
import { img2url } from "../../utils/utils";
// import { useGetAllProducts } from "@src/core/Products/hooks/useProduct";

export default function Details() {
  let { params } = useRoute<any>();
  let categories = useGetProductByCat(params.id).data?.data;
  let linkTo = useLinkTo();

  const goTO = (id) => {
    return linkTo("/menu/" + id);
  };

  return (
    <View style={styles.container}>
      <Hero name={params.name} image={img2url(params.image)} />
      <View style={styles.list}>
        <SkeletonMenu>
          {categories?.map((item, index) => {
            return (
              <Product
                key={index}
                name={item.name}
                price={item.price}
                description={item.description}
                image={img2url(item?.image)}
                onPress={() => {
                  goTO(item?.id);
                }}
                navigation={{
                  id: item?.id,
                  image: item?.image,
                }}
              />
            );
          })}
        </SkeletonMenu>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    // flex: 1,
    height: "100%",
  },
  list: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    gap: 2,
    paddingHorizontal: 10,
  },
});
