import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  Touchable,
} from "react-native";
import React from "react";
import { TouchableOpacity } from "react-native-web";
import { Link } from "@react-navigation/native";

const toLowerCase = (str) => {
  // make lowercase and UpperCase first letter
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export default function CatCards(props) {
  return (
    <Link
      style={styles.container}
      to={{ screen: "Details", params: props.navigation }}
    >
      <ImageBackground
        style={styles.image_background}
        source={{ uri: props.image }}
        resizeMode="cover"
        imageStyle={{ width: "100%", height: "100%" }}
      >
        <View style={styles.wrapper}>
          <Text style={styles.title}>{toLowerCase(props.name)}</Text>
        </View>
      </ImageBackground>
    </Link>
  );
}

const styles = StyleSheet.create({
  container: {
    minWidth: "48%",
    height: 150,
    borderRadius: 20,
    overflow: "hidden",
    marginBottom: 20,
  },
  image_background: {
    width: "100%",
    height: "100%",
  },
  wrapper: {
    backgroundColor: "rgba(0,0,0,0.5)",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  title: {
    color: "white",
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 0,
    padding: 10,
  },
});
