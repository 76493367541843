import { API_URL } from "../constants";

export const getCats = async (q) => {
  console.log("q", q);
  let response = await fetch(`${API_URL}/mobile/categories`);
  let data = await response.json();
  return data;
};

export const getProductByCat = async (query) => {
  let [key, cat_id] = query.queryKey;
  console.log("cat_id", query);
  let response = await fetch(`${API_URL}/mobile/product/?category=${cat_id}`);
  let data = await response.json();
  return data;
};

export const getOneCategory = async (query) => {
  let [key, cat_id] = query.queryKey;
  console.log("cat_id", query);
  let response = await fetch(`${API_URL}/mobile/categories/${cat_id}`);
  let data = await response.json();
  return data;
};

export const getOneProduct = async (query) => {
  let [key, product_id] = query.queryKey;
  console.log("product_id", query);
  let response = await fetch(`${API_URL}/mobile/product/${product_id}`);
  let data = await response.json();
  return data;
};
