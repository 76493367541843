import { StyleSheet, Text, View, ScrollView } from "react-native";
import React from "react";
import Logo from "../../components/Logo";
import CatCards from "../../components/catsCard/catCards";
import { useNavigation } from "@react-navigation/native";
import { ActivityIndicator } from "react-native-web";
import SkeletonMenu from "./components/skeletonMenu";
import { useGetCategories } from "../../hooks/useRequest";
import { img2url } from "../../utils/utils";

export default function Home() {
  let navigation = useNavigation();
  let categories = useGetCategories({}).data?.data;

  return (
    <View style={styles.container}>
      <Logo />

      <View style={styles.listView}>
        {categories?.map((item, index) => {
          return (
            <CatCards
              key={index}
              name={item.name}
              image={img2url(item?.image)}
              navigation={{
                id: item?.id,
                image: item?.image,
              }}
            />
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    // height: "100%",
    paddingHorizontal: 10,
    // flex: 1,
  },
  listView: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    marginTop: 70,
  },
});
