import { StatusBar } from "expo-status-bar";
import { StyleSheet, Text, View } from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { QueryClient, QueryClientProvider } from "react-query";
import Home from "./screens/home";
import Details from "./screens/details";
import MenuDetails from "./screens/menuDetails";

const queryClient = new QueryClient();

export default function App() {
  let Stack = createNativeStackNavigator();
  return (
    <QueryClientProvider client={queryClient}>
      <NavigationContainer
        documentTitle={{
          enabled: true,
          formatter: (options, route) =>
            `${options?.title ?? route?.name ?? "Kariya Food"}`,
        }}
        // documentTitle="Kariya Food"
        linking={{
          prefixes: ["https://kariya.mamadi.dev", "kariya://"],
          config: {
            screens: {
              Home: "",
              Details: "details/:id",
              MenuDetails: "menu/:id",
            },
          },
        }}
      >
        <Stack.Navigator>
          <Stack.Screen
            name="Home"
            component={Home}
            options={{ headerShown: false, title: "Kariya Food" }}
          />
          <Stack.Screen
            name="Details"
            component={Details}
            options={{ headerShown: false, title: "Kariya Food" }}
          />
          <Stack.Screen
            name="MenuDetails"
            component={MenuDetails}
            options={{ headerShown: false, title: "Kariya Food" }}
          />
        </Stack.Navigator>
      </NavigationContainer>
    </QueryClientProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
