import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import React from "react";
import Counter from "./Counter";
import { IOFPickerProps } from "./types";
import { priceFormatter } from "@src/shared/utils/formatter";
import CheckBox from "./CheckBox";
import { img2url } from "@src/utils/utils";
// import { imageRenderer } from "@core/Products/helpers"

export function OFFoodOptionPicker(props: IOFPickerProps) {
  const dynamicStyle = {
    opacity: props.isDisabled ? 0.2 : 1,
  };

  return (
    <TouchableOpacity
      disabled
      style={[styles.container, dynamicStyle]}
      onPress={props.isDisabled ? () => {} : props.onPress}
    >
      <View style={styles.left}>
        <Image
          style={{ width: 30, height: 30, borderRadius: 5 }}
          source={{ uri: img2url(props.image) }}
        />
        <View>
          <Text style={styles.label}>{props.label}</Text>
          <Text style={{ color: "#999", marginTop: 2 }}>
            +{priceFormatter(props.price)}
          </Text>
        </View>
      </View>
      <View style={styles.left}>
        {/* {props.isChecked && props.multiple ? (
          <Counter
            value={props.quantity || 1}
            onDecrement={props.onDecrement}
            onIncrement={props.onIncrement}
          />
        ) : (
          <CheckBox type={props.type} isChecked={props.isChecked} />
        )}
        {/* <Counter
          value={0}
          onDecrement={props.onDecrement}
          onIncrement={props.onIncrement}
        /> */}
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 70,
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
  },
  left: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  },
  label: {
    fontSize: 16,
    fontWeight: "700",
    color: "black",
  },
});
