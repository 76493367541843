import { StyleSheet, Text, View, ImageBackground, Image } from "react-native";
import React from "react";
export default function Hero(props) {
  return (
    <ImageBackground
      source={{ uri: props.image }}
      style={styles.container}
      blurRadius={20}
    >
      <View style={styles.wrapper}>
        <Image
          source={require("../../../../assets/icon.png")}
          style={styles.Image}
        />
        <Text style={styles.title}>{props.name}</Text>
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 180,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
  },
  wrapper: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: "white",
    fontSize: 30,
    fontWeight: "bold",
    marginBottom: 20,
  },
  Image: {
    width: 100,
    height: 100,
    borderRadius: 0,
  },
});
