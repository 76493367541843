import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { Skeleton } from "moti/skeleton";

export default function SkeletonMenu(props) {
  return (
    <View style={styles.container}>
      {props?.children?.length === 0
        ? [1, 2, 3, 4].map((item, index) => {
            return <Skeleton colorMode="light" width={178} height={180} />;
          })
        : props.children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: 10,
    rowGap: 20,
  },
});
