/// NOTES ////
/// Use Class to manage options helpers

import { StyleSheet, Text, View } from "react-native";
import React from "react";
// import { Colors } from "@shared/constants/colors"
import { Chip } from "react-native-paper";
import { OFFoodOptionPicker } from "../OFPicker";
import {
  IOptionOnStore,
  IProductOption,
  IProductOptionElement,
  IProductOptionElementWithQuantity,
} from "@core/Products/types";

interface IOptions extends Partial<IProductOption> {
  value: Partial<IProductOptionElementWithQuantity>[];
  onPress: (selectedElement: any, optionId: string) => void;
}

interface ISelectedOptionState {
  [key: string]: Partial<IProductOptionElement>[];
}

export function OFFoodOptions(props: IOptions) {
  let { title, required, choices_number, elements, optionID, onPress, value } =
    props;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={{ gap: 4 }}>
          <Text style={{ fontSize: 17, fontWeight: "bold" }}>{title}</Text>
          {choices_number >= 1 && (
            <Text style={styles.choice_text}>
              {choices_number} Choix Gratuit{choices_number > 1 ? "s" : ""}
            </Text>
          )}
        </View>
      </View>

      <View style={styles.options}>
        {elements?.map((element, index) => {
          let current_value_index = value?.findIndex(
            (el: IProductOptionElement) => {
              return el.id === element.id;
            }
          );

          return (
            <OFFoodOptionPicker
              // isDisabled={true}
              id={element.id}
              key={index}
              label={element.name}
              image={element.image}
              price={element.price}
              type="checkbox"
              isChecked={false}
              quantity={value?.[current_value_index]?.quantity}
              value="e"
            />
          );
        })}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "98%",
    minHeight: 200,
    backgroundColor: "#fff",
    elevation: 1,
    marginTop: 10,
    paddingTop: 20,
    borderWidth: 1,
    borderColor: "#ccc",
    borderRadius: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    paddingHorizontal: 10,
  },
  options: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    // backgroundColor: Colors.RED
  },

  choice_text: {
    fontSize: 14,
    color: "#e74c3c",
    backgroundColor: "#f5f5f5",
    padding: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    fontWeight: "bold",
  },
});
