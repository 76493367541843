import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import React from "react";

const priceFormat = (price) => {
  // add space between every thaousand
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

export default function Product(props: any) {
  return (
    <TouchableOpacity style={styles.container} {...props}>
      <Image
        style={styles.image}
        source={{
          uri: props.image,
        }}
      />
      <Text style={styles.title}>{props.name?.toUpperCase()}</Text>

      <Text style={styles.description}>{props.description}</Text>
      <Text style={styles.price}>{priceFormat(props.price)} FCFA</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "43.5%",
    minHeight: 200,
    marginHorizontal: "2%",
    // backgroundColor: "red",
    marginVertical: "2%",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 10,
    padding: 10,
  },

  image: {
    width: "100%",
    // flex: 1,
    height: 100,
    resizeMode: "cover",
    borderRadius: 10,
  },

  title: {
    fontSize: 18,
    marginTop: 10,
    marginBottom: 5,
  },
  price: {
    fontSize: 14,
    color: "#e74c3c",
    fontWeight: "bold",
    margin: 2,
  },
  description: {
    fontSize: 14,
    color: "#95a5a6",
    margin: 2,
  },
});
